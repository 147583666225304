.changelog .item {
  margin-bottom: 2.5em;
}
.changelog p {
  color: #888;
  line-height: 1.7em;
}

.changelog p button {
  padding: 0 5px !important;
  vertical-align: middle;
  height: unset !important;
  width: unset !important;
}
