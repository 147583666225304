.collapsed .ql-editor {
  height: 1.8em;
  opacity: 0.25;
  padding-top: 2px;
  padding-right: 40px !important;
  overflow-y: hidden;
}

.note-container {
  position: relative;
}
.note-container .show-more-less {
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.4;
}
.note-container .show-more-less button {
  padding: 4px;
  margin-right: 4px;
}
