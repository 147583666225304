/* CALENDAR COMPONENT OVERRIDE */
// $backgroundColor: rgb(240,246,247);
$backgroundColor: rgb(225, 235, 240);
$overdueColor: #e8893c;
$pastdueColor: #ef3532c7;

// @keyframes event-hover {
//   0% {
//     transform: scale(1);
//     // opacity: 1;
//   }
//   50% {
//     transform: scale(1.01);
//     // opacity: 0.9;
//   }
//   100% {
//     transform: scale(1);
//     // opacity: 1;
//   }
// }

@keyframes event-selected {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.75;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes event-selected-pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* RIGHT PANE */
#calendar-container {
  height: 100%;
  //  overflow-y: auto;
  //  -webkit-overflow-scrolling: touch;

  #calendar {
    height: 100%;
    @media (min-width: $mobileBreakPoint) {
      // desktop
      padding: 0 10%; // space for change day buttons
    }

    /* Header: Dates and buttons */
    .fc-toolbar {
      margin-bottom: 0;
      z-index: 2 !important;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 220px; // Increase if displaying more than month + year
      height: 56px;
      text-align: center;
      float: none;
      overflow-x: hidden;
      overflow-y: hidden;
      button {
        background-color: rgb(230, 230, 230);
        background-image: none;
        border: none;
        box-shadow: none;
        border-image: none;
        text-shadow: none;
        &:hover {
          background-color: rgb(215, 215, 215);
        }
        &.fc-state-active {
          background-color: rgb(200, 200, 200);
        }
      }
      .fc-toolbar-chunk {
        max-width: 100%;
      }
      h2 {
        display: inline-block;
        font-weight: 300;
        color: rgb(100, 100, 100);
        float: none;
        line-height: 58px;
        // vertical-align: top;
        margin: 0;
        padding: 0;
        font-size: 1.3em;
        color: white;
        cursor: pointer;
        user-select: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;
        @media (max-width: $mobileBreakPoint) {
          font-size: 1.68em;
          // width: 100%;
        }
        @media (max-width: $mobileXSBreakPoint) {
          font-size: 1.4em;
        }
        // Open Icon
        &:after {
          content: '';
          display: inline-block;
          width: 0;
          height: 0;
          border-top: 4px solid white;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
          margin-left: 4px;
          vertical-align: middle;
        }
      }
      // removes selection blue border in chrome
      // button:focus {
      //   outline: 0;
      // }
    }

    /* End of fc-toolbar */

    /* Calendar Views */
    .fc-view-harness {
      // .fc-view-container
      position: relative;
      padding: 0 10%;

      @media (max-width: $mobileBreakPoint) {
        padding: 0 10px 0 0;
      }

      * {
        border: none;
      }
      /* Calendar View Types */
      .fc-view {
        & > table > thead .fc-scroller-harness {
          padding: 18px 0 13px 0;
          a,
          span {
            font-size: 1.2em;
            @media (max-width: 768px) {
              font-size: 1.1em;
            }
            color: rgb(50, 50, 50);
            font-weight: 300;
          }
          .fc-day-today a {
            color: $orange;
            font-weight: 400;
          }
        }

        // All day label
        .fc-timegrid-axis-cushion {
          color: rgba(0, 0, 0, 0.3);
          font-weight: 300;
          font-size: 16px;
          position: relative;
          top: 0px;
          right: 4px;
        }

        // center day label on mobile on daily view
        &.fc-daily-view .fc-col-header .fc-timegrid-axis {
          @media (max-width: $mobileBreakPoint) {
            display: none;
          }
        }
        & > table > tbody {
          .fc-daygrid-body {
            // All-day events field
            .fc-day-today {
              background: none;
              background-image: linear-gradient(rgba(80, 150, 190, 0.3), rgba(80, 150, 190, 0.1));
              border-radius: 9px 9px 0 0;
            }
          }
          /* Widget contains the All-day events grid and the Main calendar grid.
						All-day events grid's height is based on content (number of all-day events)
						I tried to make the Main grid height take the remaining space from Widget container
						Only way was either flex (which doesn't work with tables) or JS
						After failing for hours I'll leave all-day grid with a fixed height and a scrollbar ._.

						(\___/) |DEFEATED)
						(=*.*=) |
						("")("")| 


						DY 20181127: WE WON!!!!
						removed tons of height juggling, put calendar settings height/contentHeight to 'parent', seems to work ok now
						*/

          .fc-scroller {
            // Main calendar grid
            // height: 100%;
            height: calc(100% - 6px); // accomodates padding in header
            height: -webkit-calc(100% - 6px);
            height: -moz-calc(100% - 6px);
            overflow-y: auto !important;
            overflow-x: hidden;
            -webkit-overflow-scrolling: touch;
            &::-webkit-scrollbar {
              opacity: 0;
            }
            -ms-overflow-style: none;
            scrollbar-width: thin;
            scrollbar-color: transparent transparent;
            // Disable Text Selection in timeline - allows in-schedule task creation
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            &:hover,
            &:focus,
            &:active {
              &::-webkit-scrollbar {
                opacity: 1;
              }
              scrollbar-color: rgba(0, 0, 0, 0.5) transparent;
              -ms-overflow-style: auto;
            }

            .fc-timegrid-now-indicator-line {
              border-bottom: 3px solid $orange !important;
              opacity: 0.7;
            }
            // .fc-timegrid-now-indicator-arrow {
            //   left: 0;
            //   border: 5px solid $orange;
            //   border-top-color: transparent;
            //   border-bottom-color: transparent;
            //   border-right-color: transparent;
            // }

            .fc-day-today {
              background: rgba(80, 150, 190, 0.1); // blue highlight
            }

            .fc-timegrid-slots {
              // z-index: 0 !important; // Allows daily-highlight and borders to stay on top
              tr {
                line-height: 18px;
                td {
                  height: 18px; // cannot be lower than 1em
                  // vertical-align: top;
                  .fc-timegrid-slot-label-cushion {
                    color: rgb(50, 50, 50);
                    font-weight: 300;
                    font-size: 18px;
                    position: relative;
                    top: 0px;
                    right: 4px;
                  }
                }
              }
              tr:nth-child(8n + 1) {
                td:nth-child(2) {
                  background-color: rgba(0, 0, 0, 0.04);
                  border-radius: 4px 4px 0 0;
                }
              }
              tr:nth-child(8n + 2) {
                td:nth-child(2) {
                  background-color: rgba(0, 0, 0, 0.04);
                  border-radius: 0;
                }
              }
              tr:nth-child(8n + 3) {
                td:nth-child(2) {
                  background-color: rgba(0, 0, 0, 0.04);
                  border-radius: 0;
                }
              }
              tr:nth-child(8n + 4) {
                td:nth-child(2) {
                  background-color: rgba(0, 0, 0, 0.04);
                  border-radius: 0 0 4px 4px;
                }
              }
              tr:nth-child(24n + 1) .fc-timegrid-slot-label-cushion {
                font-weight: 500;
              }
              // Add dot indicator for each hour on the timeline
              tr:nth-child(4n + 1) {
                td:nth-child(2) {
                  position: relative;
                  &::before {
                    content: '';
                    position: absolute;
                    left: -3px;
                    top: 0;
                    transform: translateY(-50%);
                    height: 6px;
                    width: 6px;
                    border: 2px solid black;
                    border-radius: 50%;
                  }
                }
              }
            }
            .fc-timegrid-cols {
              z-index: 1; // required for background border cut
              .fc-timegrid-col {
                border-right: 2px solid rgba(0, 0, 0, 0.02); // Color of vertical time axis
                // margin-right: 4px !important;
                .fc-timegrid-col-bg {
                  z-index: 10; // displays fc-highlight for in-place task creation and task drag and drop above calendar events, each overlapping event has 1+ z-index by default.
                }
              }
            }
          }
          // .fc-divider {
          //   display: none;
          // }
        }
      } // end of .fc-view

      .fc-dragging {
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.3) !important;
      }

      /* >>>>>>>>>>>>>>>>>>>> Calendar VIEWS */
      /* CUSTOM CALENDAR VIEWS */

      .fc-month-view,
      .fc-weekly-view {
        .event-recurring-icon {
          font-size: 12px !important;
        }
        @media (max-width: 1800px) {
          .task-container-icon {
            margin: auto 0 auto 10px; // left space for recurring icon
            // width: auto !important;
          }
          .fc-event-title-container {
            margin-left: 0;
            &::before {
              // suggestion icons
              margin-left: 0;
              margin-right: 0;
            }
            .fc-event-title {
              padding-left: 4px !important;
            }
          }
          .event-task-list {
            display: none !important;
          }
        }
        // Hide checkbox on tiny desktop screens to display task title
        @media (max-width: $smallScreenBreakpoint) and (min-width: $mobileBreakPoint) {
          .task-container-icon {
            display: none;
          }
        }
      }

      .fc-daily-view {
        width: 500px;
        margin: 0 auto;
        @media (min-width: $smallScreenBreakpoint) and (max-width: $mobileXSBreakPoint) {
          margin-right: 25%;
        }
        @media (max-width: 768px) {
          width: 100%;
        }
        .fc-state-highlight {
          background: rgba(80, 150, 190, 0) !important; // hide highlight on daily view
        }
        .fc-today span {
          color: $orange !important;
        }
      }

      // DATE PICKER
      .fc-selectDate-view {
        max-width: 700px;
        margin: 0 auto;
        .fc-multimonth-month {
          margin: 30px 0;
        }
        .fc-daygrid-day,
        .fc-multimonth-daygrid {
          background: none;
          .fc-daygrid-day-top {
            text-align: center;
            height: 100%;
            width: 100%;
            border-radius: 8px;
            a {
              color: $primary-color;
              font-size: 2.5em;
              font-weight: 100;
              line-height: 60px;
              padding-right: 9px;
              width: 100%;
              text-decoration: none;
              @media (max-width: $mobileBreakPoint) {
                font-size: 1.6em;
                padding-right: 3px;
              }
            }
            &:hover {
              background: rgba(80, 150, 190, 0.1);
            }
          }
          &.fc-day-past a {
            color: rgba(0, 0, 0, 0.5);
          }
          &.fc-day-today a {
            color: white;
          }
        }
        .fc-multimonth-daygrid {
          background: rgb(240, 245, 250);
          border-radius: 0 0 16px 16px;
          padding: 5px;
        }
        .fc-multimonth-header {
          background: rgb(240, 245, 250);
          border-radius: 16px 16px 0 0;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
          padding-bottom: 10px;
        }
        .fc-daygrid-day-events,
        .fc-daygrid-day-bg {
          display: none !important;
        }
      }
    }
    /* End of fc-view-container */
  }

  .fc-daygrid {
    .fc-scrollgrid-section {
      border-top: 1px solid #e0e0e0 !important;
    }
    .fc-day-today .fc-daygrid-day-number {
      border-radius: 8px;
      background-color: orange;
      color: white;
    }
  }
  .fc-daygrid-day-frame {
    .fc-daygrid-day-events {
      padding-bottom: 4px;
    }
  }

  .fc-view:not(.fc-daygrid) tbody .fc-scrollgrid-section:nth-child(1) .fc-scroller-harness {
    .fc-scroller {
      max-height: 135px; // Limit all-day height to 5 events + 5px hinting next event. Limit only applies to timeviews (daily/weekly, not monthly)
      overflow: scroll;
    }
  }

  // .fc-timegrid-bg-harness {
  //   z-index: 100;
  // }

  // .fc-highlight {
  //   z-index: 5;
  // }

  .fc-highlight {
    z-index: 6; // 1 above an event
    opacity: 1;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    margin: 1px 1px 1px 5px;
    .titleField {
      width: 100%;
      height: 100%;
      min-height: 41px;
      color: rgb(50, 50, 50) !important;
      padding: 0 8px;
      border-bottom: 1px solid rgb(240, 240, 240);
      background-color: white;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
      border-radius: inherit;

      &:focus {
        outline: none;
      }
    }
  }
} // End of #calendar

/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> _Event_default */

// .fc-timegrid-event-hartness
// a.fc-timegrid-event
// .fc-event-main
// .fc-event-main-frame and resizer
// .fc-event-time, .fc-event-title, .fc-event-checkbox, .fc-event-list

.fc-timegrid-event-harness,
.fc-daygrid-event-harness {
  border-radius: 8px;
}

a.fc-timegrid-event,
a.fc-daygrid-event {
  // Disable text selection magnifier
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 8px;
  // sets-background-color
  // sets-border-color

  &.fc-timegrid-event {
    margin: 1px 0 1px 2px;
    border-left-width: 4px;
  }

  .fc-event-main {
    padding: 0; // overrides default
    width: 100%;
  }
  .fc-event-main-frame {
    // Styling in EventView.tsx
    width: -webkit-calc(100% - 4px);
    width: -moz-calc(100% - 4px);
    width: calc(100% - 4px); // space to show underlying event color from a.fc-timegrid-event
    margin: 0 0 0 auto; // shows underlying color on left
    border-radius: 6px;
    height: 100% !important;
    .fc-event-main-frame-container {
      height: 100%;
      width: 100%;
      max-height: 47px !important;
      display: flex;
      flex-direction: row !important;
      align-items: center;
      overflow: hidden;
      position: relative;

      .suggestion-options-button {
        display: none;
        position: absolute;
        top: 2px;
        right: 2px;
        height: 30px;
        width: 30px;
        padding: 3px;
        backdrop-filter: blur(5px);
        svg {
          height: 24px;
          width: 24px;
        }
      }
    }
    &:hover .suggestion-options-button {
      display: flex;
    }
    .fc-event-time {
      display: none;
    }
    span {
      // checkbox / icon
      max-height: 100%;
      align-items: center;
    }
    .fc-event-title-container {
      display: flex;
      align-items: center;
      max-width: 100%;
      height: 100%;
      overflow: hidden;
      .fc-event-title {
        padding-left: 8px !important;
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; // truncates on 2nd line if not short
      }
    }
  }

  &.fc-daygrid-event {
    margin-right: 6px !important;
    // all-day event that spans multiple days should have visual hint
    &:not(.fc-event-end) {
      width: 100%;
      .fc-event-main-frame {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
    &:not(.fc-event-start) {
      width: 100%;
      .fc-event-main-frame {
        width: 100%;
        margin: 0;
        padding-left: 6px;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }
    .fc-event-title {
      line-height: 23px !important;
    }
    &.event-task {
      background-color: rgb(255, 255, 255) !important;
      border-color: rgb(255, 255, 255);
      &.fc-dragging {
        background-color: rgb(255, 255, 255) !important;
      }
      &:hover .fc-event-main-frame {
        background-color: rgba(0, 0, 0, 0);
      }
    }
    .fc-event-main-frame {
      min-height: 22px !important;
      // @include animate(0.3s);
    }
    &.fc-daygrid-dot-event {
      padding: 0 !important;
    }
    // &:hover {
    //   .fc-event-main-frame {
    //     background-color: rgba(235, 235, 235, 0.25);
    //   }
    // }
    &.suggestion-event {
      background-color: rgba(255, 255, 255, 0.5) !important;
      &:hover {
        background-color: rgba(255, 255, 255, 0.6) !important;
      }
    }
    &.suggestion-event-overdue {
      .fc-title {
        color: $overdueColor !important;
      }
      border-color: $overdueColor !important;
    }
  }

  /* 15-30 minute events */
  &.fc-timegrid-event-short {
    z-index: 2 !important;
    min-height: 16px;

    .fc-event-time {
      display: none;
    }

    .fc-event-main-frame {
      .task-checkbox {
        height: 16px;
        width: 16px;
        margin: auto 4px auto 4px;
      }
      .partially-completed-icon {
        height: 18px;
        width: 18px;
        margin: auto 2px auto 6px;
      }
    }
    &.suggestion-event svg {
      height: 16px !important;
      width: 16px !important;
      margin: auto 3px auto 3px !important;
    }
    .suggestion-options-button {
      height: 16px !important;
      width: 16px !important;
      padding: 2px !important;
      top: 0 !important;
      svg {
        height: 12px !important;
        width: 12px !important;
      }
    }
  }
  /* END FC-SHORT */

  &.suggestion-event {
    border-radius: inherit;
    background-color: rgba(255, 255, 255, 0.4) !important;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.25);
    border-width: 0 0 0 4px !important;
    border-style: solid !important;
    @supports ((backdrop-filter: blur(10px)) or (-webkit-backdrop-filter: blur(10px))) {
      & {
        backdrop-filter: blur(10px);
      }
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.5) !important;
      cursor: pointer;
      .trevor-icon {
        display: none;
      }
      .trevor-icon-hover {
        display: block !important;
      }
    }
  }
  &.suggestion-event-overdue {
    border-color: $overdueColor !important;
    .fc-event-title-container {
      display: inline-flex;
      align-items: center;
      &::before {
        content: url("data:image/svg+xml,%3Csvg height='18' viewBox='0 0 48 48' width='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h48v48h-48z' fill='none'/%3E%3Cpath d='M25.99 6c-9.95 0-17.99 8.06-17.99 18h-6l7.79 7.79.14.29 8.07-8.08h-6c0-7.73 6.27-14 14-14s14 6.27 14 14-6.27 14-14 14c-3.87 0-7.36-1.58-9.89-4.11l-2.83 2.83c3.25 3.26 7.74 5.28 12.71 5.28 9.95 0 18.01-8.06 18.01-18s-8.06-18-18.01-18zm-1.99 10v10l8.56 5.08 1.44-2.43-7-4.15v-8.5h-3z' fill='%23e8893c' opacity='.9'/%3E%3C/svg%3E");
        height: 18px;
        width: 18px;
        margin-left: 8px;
        margin-right: 4px;
      }
    }
    .fc-event-title {
      color: $overdueColor !important;
    }
    &:hover {
      .fc-event-title-container::before {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 448 512'%3E%3Cpath d='M384 432c8.8 0 16-7.2 16-16l0-320c0-8.8-7.2-16-16-16L64 80c-8.8 0-16 7.2-16 16l0 320c0 8.8 7.2 16 16 16l320 0zm64-16c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96C0 60.7 28.7 32 64 32l320 0c35.3 0 64 28.7 64 64l0 320zM224 352c-6.7 0-13-2.8-17.6-7.7l-104-112c-6.5-7-8.2-17.2-4.4-25.9s12.5-14.4 22-14.4l208 0c9.5 0 18.2 5.7 22 14.4s2.1 18.9-4.4 25.9l-104 112c-4.5 4.9-10.9 7.7-17.6 7.7z' fill='%23e8893c' opacity='.9'/%3E%3C/svg%3E");
        margin-left: 10px;
        margin-right: 2px;
      }
    }
  }

  // Hightlight and hover effects
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2) !important; // @include animate(0.2s);
  transition: transform 0.25s;
  &:not(.suggestion-event):not(.fc-event-selected):hover {
    transform: scale(1.01);
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.3) !important; // overrides default rules
    .event-task-list {
      @media (min-width: $mobileBreakPoint) {
        opacity: 1 !important;
      }
    }
  }
  &:not(.fc-timegrid-event-short, .fc-daygrid-event) .fc-event-title {
    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial !important;
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background-color: rgba(255, 255, 255, 1);
    opacity: 0;
    @include animate(0.3s);
  }
  &:hover::before {
    opacity: 0.15;
  }
  &.fc-event-selected {
    animation: event-selected 0.5s, event-selected-pulse 1.5s infinite;
    animation-delay: 0s, 0.5s;
    // class gets asigned before and after the eventLongPressDelay
  }
  &.fc-event-selected::before {
    opacity: 0 !important;
  }
}

// IF EVENT WITH TASK
.fc-timegrid-event,
.fc-daygrid-event {
  .event-task {
    .fc-event-title-container {
      width: calc(100% - 20px); // checkbox
      .fc-event-title {
        color: rgb(50, 50, 50);
      }
    }
    .task-checkbox {
      height: 22px;
      width: 22px;
      & svg {
        height: inherit;
        width: inherit;
      }
      margin: 2px;
      min-width: 12px;
      cursor: pointer;
    }
    .event-task-list {
      position: absolute !important;
      right: 0;
      height: 100%;
      padding-left: 30px;
      background: rgba(255, 255, 255, 1);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 35%,
        rgba(255, 255, 255, 1) 100%
      );
      border-radius: 6px;
      opacity: 0;
      max-width: 100%;
      @include animate(0.2s);
      font-style: italic;
      div {
        padding: 0 5px;
        vertical-align: baseline;
        font-size: 13px;
        line-height: 13px;
      }
    }

    &.event-task-completed {
      .fc-event-title {
        font-style: italic;
        color: rgb(165, 165, 165) !important; // Ignores partially completed events
      }
    }

    .partially-completed-icon {
      display: block;
      color: rgb(110, 110, 110);
      fill: rgb(117, 117, 117);
      height: 22px;
      width: 22px;
      & svg {
        height: inherit;
        width: inherit;
      }
      margin-left: 4px;
    }
    .event-task-due-mismatch-label {
      color: $pastdueColor;
      font-size: 0.6rem;
      text-align: right;
      padding: 4px;
      opacity: 0.7;
    }
    &.event-task-due-mismatch {
      flex-direction: row;
      align-items: flex-start; // event title should be on top, irrelevant of event height
    }
  }
  &.fc-timegrid-event-short .event-task-due-mismatch-label {
    width: 22px;
  }
}

// Clear focus default
.fc-event:focus:after,
.fc-event-selected:after {
  background: rgba(0, 0, 0, 0) !important; // perserve larger hit marker
}

.fc-timegrid-event .fc-event-resizer {
  display: block;
  background: rgba(0, 0, 0, 0) !important;
  opacity: 0;
  width: 50px;
  max-width: 33%;
  height: 50% !important;
  max-height: 25px;
  // margin: 0 auto !important;
  left: 50% !important;
  right: auto !important;
  transform: translatex(-50%);
  @include animate(0.3s);
  &:after {
    content: '';
    width: 12px;
    height: 12px;
    // margin: 0 auto !important;
    background-color: white; /* universal color for both tasks and events */
    // border: 2px solid $primary-color;
    box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.33);
    // background: inherit;
    border-radius: 50%;
    position: absolute;
    // top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include animate(0.1s);
  }
  &.fc-event-resizer-end {
    bottom: 0 !important;
    &:after {
      bottom: -12px !important;
    }
  }
  &.fc-event-resizer-start {
    top: 0 !important;
    &:after {
      top: 0 !important;
    }
  }
  &:hover:after {
    box-shadow: 1px 0px 8px rgba(0, 0, 0, 0.5);
  }
}

// .fc-timegrid-event:not(.event-task) .fc-event-resizer:after {
//   // color: white;
// }
.fc-event {
  animation: fadeIn 0.3s ease-in;
  @media (min-width: $mobileBreakPoint) {
    &:hover,
    &:focus,
    &.fc-event-selected {
      .fc-event-resizer {
        opacity: 1;
      }
    }
  }
  @media (max-width: $mobileBreakPoint) {
    .fc-event-resizer {
      height: 1px !important; // resizer hitbox prevents going into selected mode on mobile so we reduce it to 1px
    }
    // Do not display resizer on hover and focus on mobile
    &.fc-event-selected {
      .fc-event-resizer {
        opacity: 1;
        height: 50% !important; // once the event is selected, we restore the hitbox to 50% height
      }
    }
  }
}
.fc-event:focus,
.fc-event:focus *,
.fc-event.fc-event-selected,
.fc-event.fc-event-selected * {
  // remove animation delays for hover effects - list and resizers, visible when moving an event
  transition: none !important;
}
/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> _Event_default_END */

.fc-helper-container > * {
  // position: relative !important;
  z-index: 5 !important; // 1 above placeholder
}

.previous-day-button,
.next-day-button {
  cursor: pointer;
  color: rgb(80, 150, 190);
  display: flex; /*CSS3*/
  z-index: 1 !important; // below dragged events
  align-items: center; /*Vertical align*/
  justify-content: center; /*horizontal align*/
  z-index: 1111;
  position: absolute;
  min-width: 30px;
  width: 8%;
  // height: 48px; // height of all-day section
  height: 100%;
  top: 0;
  border-radius: 24px;
  &:hover {
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0.2) 100%
    );
  }
  @media (max-width: $mobileBreakPoint) {
    display: none;
  }
}

.previous-day-button {
  left: 0;
}

.next-day-button {
  right: 0;
}

.go-to-today {
  border-bottom: solid 4px $orange !important;
  border-radius: 0 !important;
}

.fc-daily-view .fc-day-today {
  background: none !important; // removes blue highlight
}

#calendar .fc-view-container {
  animation-duration: 0.3s;
  webkit-animation-duration: 0.3s;
  moz-animation-duration: 0.3s;
  o-animation-duration: 0.3s;
}

// Calendar Options > Schedule View

.compact .fc-short {
  min-height: 16px !important;
}

.detailed .fc-short {
  min-height: 20px !important;
}

.compact .fc-timegrid-slots {
  tr {
    line-height: 10px !important;
  }
  td {
    height: 10px !important;
  }
}

.detailed .fc-timegrid-slots {
  td {
    height: 26px !important;
  }
  tr:nth-child(4n + 3) .fc-timegrid-slot-label-cushion {
    // hour label
    color: rgba(0, 0, 0, 0.25) !important;
  }
}

#calendar .fc-scrollgrid-section-header .fc-scroller {
  overflow: hidden !important; // hides scrollbar on header dates
}

// Dragging Behavior using react-beautiful-dnd
// Disable clone when dragging task
.fc-event-dragging {
  opacity: 0 !important;
}
// Style calendar event as placeholder when dragging over calendar
#calendar .fc-event-dragging {
  opacity: 1 !important;
  background-color: white !important;
  box-shadow: none !important;
  * {
    // Hide everything besides white placeholder box
    display: none;
  }
}

// Timeline
.fc-timegrid-body .fc-timegrid-axis {
  border-right: 2px solid black !important;
}

.fc-col-header-cell {
  border-radius: 8px;
}
