$primary-color: rgb(80, 150, 190);

@keyframes glow {
  0% {
    -moz-box-shadow: 0 0 5px $primary-color;
    -webkit-box-shadow: 0 0 5px $primary-color;
    box-shadow: 0 0 5px $primary-color;
  }
  50% {
    -moz-box-shadow: 0 0 30px $primary-color;
    -webkit-box-shadow: 0 0 30px $primary-color;
    box-shadow: 0 0 30px $primary-color;
  }
  100% {
    -moz-box-shadow: 0 0 5px $primary-color;
    -webkit-box-shadow: 0 0 5px $primary-color;
    box-shadow: 0 0 5px $primary-color;
  }
}

.plans {
  position: relative;
  height: calc(100% - 104px); // Header and Padding
  -webkit-height: calc(100% - 104px); // Header and Padding
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  .premium-header {
    max-width: 500px;
    color: white;
    h1 {
      font-weight: 300;
    }
    a {
      color: white;
    }
  }
}

.plan-card {
  flex: 0 0 300px;
  min-height: 350px;
  padding: 1em;
  margin: 1em;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: transform 0.2s;
  &:first-child {
    background-color: rgb(240, 240, 240) !important;
  }
  .title {
    text-align: center;
    font-weight: 500;
    flex: 0 0 40px;
    font-size: larger;
  }
  .description {
    flex: 1 0 auto;
  }
  .status {
    text-align: center;
    font-weight: 500;
    padding: 1em;
  }
}

.upgrade-modal {
  ul {
    margin-bottom: 5px;
    li {
      padding-bottom: 20px;
    }
  }
}

.upgrade-modal-module {
  margin: 0 auto 0 auto;
  width: 100%;
  height: auto;
  // background: url('/images/trevor_upgrade_module_color3.png') no-repeat;
  // background-size: 300px;
  text-align: center;
  &:hover {
    // background: url('/images/trevor_upgrade_module_color4.png') no-repeat;
    // background-size: 300px;
    transition: 0.5s linear;
  }
  h1 {
    font-size: 1.8em;
    font-weight: 500;
    padding: 70px 60px 0px 60px;
    color: $primary-color;
  }
  p {
    color: black;
    padding: 0 60px 60px 60px;
    text-align: center;
  }
  .module-image {
  }
  .module-title {
  }
  .module-description {
  }
  .upgrade-module-pricing {
    // display: block;
  }
  .upgrade-modal-premium {
    text-align: center !important;
  }
}

.module-socket {
  position: relative;
  width: 250px;
  height: 250px;
  margin: 15px auto;
  // size + margin should equal gridTemplateColumns's auto-fill in premium.tsx
  -moz-box-shadow: inset 0 0 5px #555555;
  -webkit-box-shadow: inset 0 0 5px #555555;
  box-shadow: inset 0 0 5px #555555;
  border-radius: 60px;
  // border: solid 5px rgb(219, 219, 219);
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    border-radius: 60px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    // background: url('../public/images/circuit-board-pattern.png') no-repeat center;
    background: url(./assets/circuit-board-pattern.png) no-repeat center;
    // filter: invert(50%) sepia(98%) saturate(256%) hue-rotate(158deg) brightness(89%) contrast(88%);
  }
  .module-chip-container {
    perspective: 1000px;
    height: 100%;
    width: 100%;
  }
  .module-chip,
  .module-chip-back {
    position: absolute;
    top: 22px;
    left: 22px;
    width: calc(100% - 50px);
    height: calc(100% - 50px);
    background-color: white;
    background: linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgb(255, 255, 255) 50%, rgba(240, 240, 240, 1) 100%);
    border: 1px solid #bababa;
    border-width: 1px 4px 4px 1px;
    border-radius: 40px;
    -moz-box-shadow: 0 0 15px #555555;
    -webkit-box-shadow: 0 0 15px #555555;
    box-shadow: 0 0 15px #555555;
    backface-visibility: hidden;
    transition: transform 0.4s;
    .module-icon {
      position: relative;
      top: 0;
      left: 0;
      margin: 0 auto;
      width: inherit;
      height: inherit;
      background-color: none;
      border-radius: inherit;
      border: none;
      // filter: invert(50%) sepia(100%) saturate(100%) hue-rotate(0deg) brightness(50%) contrast(100%);
      filter: opacity(60%);
    }
  }
  .module-title {
    position: absolute;
    bottom: 20px;
    // left: 15px;
    // right: 15px;
    min-height: 38px;
    margin: 0 auto;
    padding: 0 15px;
    width: calc(100% - 30px);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; // truncates on 2nd line
    -webkit-box-orient: vertical;
  }
  .module-chip-back {
    transform: rotateY(180deg);
    .module-chip-description {
      padding: 25px;
      display: flex;
      align-items: center;
      text-align: left;
      height: calc(100% - 50px);
      font-size: 0.9em;
    }
  }
  .module-chip-container:hover .module-chip {
    transform: rotateY(180deg);
  }
  .module-chip-container:hover .module-chip-back {
    transform: rotateY(0deg);
  }
  &.module-active,
  &:hover {
    &:before {
      transition: 0.5s linear;
      -webkit-transition: 0.5s linear;
      filter: invert(50%) sepia(98%) saturate(256%) hue-rotate(158deg) brightness(89%) contrast(88%);
      opacity: 1;
    }
    .module-chip {
      // transition: .5s linear;
      // -webkit-transition: .5s linear;
      animation: glow 3s infinite;
      -moz-animation: glow 3s infinite;
      -webkit-animation: glow 3s infinite;
      -o-animation: glow 3s infinite;
    }
    .module-icon {
      // transition: .2s linear;
      // -webkit-transition: .2s linear;
      filter: invert(50%) sepia(98%) saturate(256%) hue-rotate(158deg) brightness(89%) contrast(88%);
      // opacity: 1;
    }
  }
  &.module-demo {
    .module-chip {
      animation: glow 3s infinite;
      -moz-animation: glow 3s infinite;
      -webkit-animation: glow 3s infinite;
      -o-animation: glow 3s infinite;
    }
  }
}

.premium-gradient {
  // background: linear-gradient(90deg, #4ed8ff 0%, #82e6cb 100%);
  background: linear-gradient(90deg, #377da5 0%, #6eafd2 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  color: transparent;
}

.green-gradient {
  background: linear-gradient(90deg, #489aa7 0%, #6fccc2 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  color: transparent;
}

.black-gradient {
  background: linear-gradient(to top right, black 0%, #969696 100%);

  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  color: transparent;
}
