@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700&display=swap');

// https://www.canva.com/colors/color-wheel/
$primary-color: rgb(80, 150, 190); // #5096be
// $backgroundColor: rgb(240,246,247); // white at 0.9 opacity
$backgroundGradientBlue: #2e6aa6; // old: rgba(41,93,145,1)
$backgroundGradientGreen: #72ccb4; // old: rgba(130,230,203,1)
$backgroundColor: rgb(225, 235, 240); // white at 0.9 opacity
$orange: #ff8c2c; // old #f0a037
$purple: #505fbe;
$teal: #50beaf;
$smallScreenBreakpoint: 1200px;
$mobileBreakPoint: 1050px;
$mobileXSBreakPoint: 768px;
$taskPanelWidth: 460px;
$taskPanelSmaller: 380px;
$navBarHeight: 54px; // was 64px;

@mixin animate($sec) {
  -webkit-transition: $sec ease-out;
  -moz-transition: $sec ease-out;
  -o-transition: $sec ease-out;
  transition: $sec ease-out;
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

.animation-fade {
  animation: fade 15s;
  -moz-animation: fade 15s;
  -webkit-animation: fade 15s;
  -o-animation: fade 15s;
}

a {
  color: $primary-color;
  text-decoration: none;
  &:visited {
    color: $primary-color;
    text-decoration: none;
  }
  &:hover {
    color: $primary-color;
    text-decoration: underline;
  }
}

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
}
::-webkit-scrollbar-thumb {
  background: #a5a5a5;
}
::-webkit-scrollbar-thumb:hover {
  background: $primary-color;
}

html {
  overflow: hidden; // Prevents the body from scrolling which causes the scrolling to stuck in place, making the app seem unresponsive.
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  background-color: rgb(240, 240, 240);
  min-width: 100%;
  @media (max-width: $mobileBreakPoint) {
    position: fixed; // Prevent body scrolling on mobile
  }
}

.trevor-font {
  font-family: 'Roboto Slab', 'Roboto', 'Helvetica Neue', Arial, sans-serif;
}

hr {
  border-top: none;
  border-bottom: 1px solid rgb(200, 200, 200);
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

h1 {
  font-weight: 200;
}

// MATERIAL UI OVERRIDES
input {
  margin-top: 0;
}

.dark-blue {
  color: $primary-color;
}
.faded {
  color: rgb(175, 175, 175);
  a {
    color: rgb(175, 175, 175);
    &:hover {
      color: $primary-color;
      text-decoration: underline;
    }
  }
}

.row {
  margin: 0;
}

.col-xs,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-offset-0,
.col-xs-offset-1,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9 {
  padding: 0;
}

// QUILL TEXT EDITOR

.ql-toolbar {
  span:hover,
  .ql-selected,
  .ql-active {
    color: $primary-color;
  }
  .ql-picker-options {
    z-index: 10;
  }
  .ql-picker-item {
    color: black;
  }
}
.ql-editor {
  padding: 10px;
  &.ql-blank::before {
    color: rgb(200, 200, 200) !important;
  }
  p {
    font-weight: 400;
    color: black;
    font-size: 14px;
    margin: 0 !important;
  }
}
.ql-container {
  height: auto !important;
}

.note-container.compact {
  .ql-editor {
    margin: 0px !important;
    padding: 0px !important;
  }
  .ql-container {
    padding: 0px !important;
    margin: 0px !important;
  }
}

.pulsing-bg {
  background-color: inherit;
  animation-name: pulsing;
  animation-duration: 2s;
  animation-delay: 1s;
  animation-iteration-count: 5;
  border-radius: inherit;
  &.infinite {
    animation-iteration-count: infinite;
  }
}

.highlight {
  background-color: $primary-color;
  animation-name: pulsing;
  animation-duration: 2s;
  // animation-delay: 4s;
  animation-iteration-count: 5;
  border-radius: inherit;
  &.infinite {
    animation-iteration-count: infinite;
  }
}

.flash-pulse {
  background-color: $primary-color;
  animation-name: flashPulse;
  animation-duration: 1s;
  // animation-delay: 4s;
  animation-iteration-count: 2;
  border-radius: inherit;
  &.infinite {
    animation-iteration-count: infinite;
  }
}

.levitate {
  -webkit-animation-name: levitating;
  -webkit-animation-duration: 1000ms;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-iteration-count: 10;
  -webkit-animation-timing-function: linear;
}

@keyframes flashPulse {
  0% {
    background-color: inherit;
  }
  50% {
    background-color: $orange;
  }
  100% {
    background-color: inherit;
  }
}

@keyframes pulsing {
  0% {
    background-color: inherit;
  }
  50% {
    background-color: rgba(0, 0, 0, 0.1);
  }
  100% {
    background-color: inherit;
  }
}

@keyframes levitating {
  0% {
    -webkit-transform: translateY(0);
  }
  25% {
    -webkit-transform: translateY(-2px);
  }
  50% {
    -webkit-transform: translateY(0);
  }
  75% {
    -webkit-transform: translateY(2px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#app_root {
  // .html
  height: 100%;
  background: rgb(41, 93, 145);
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzI5NWQ5MSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM4MmU2Y2IiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(left, $backgroundGradientBlue 0%, $backgroundGradientGreen 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    $backgroundGradientBlue 0%,
    $backgroundGradientGreen 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    $backgroundGradientBlue 0%,
    $backgroundGradientGreen 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$backgroundGradientBlue, endColorstr=$backgroundGradientGreen,GradientType=1 ); /* IE6-8 */

  #error_hint,
  #error_hint_noscript {
    color: white;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    max-width: 100%;
    ol {
      text-align: left;
      li {
        padding: 10px 0;
      }
    }
    a {
      color: white;
    }
  }

  // Class Navbar
  #navbar {
    .menu-icon-button-left img {
      // Trevor Logo
      height: 40px !important;
      width: 40px !important;
      background: none !important;
      &:hover {
        -webkit-animation: logospin 1s ease-in-out infinite;
        -moz-animation: logospin 1s ease-in-out infinite;
        animation: logospin 1s ease-in-out infinite;
      }
    }
    .monk:hover {
      -webkit-animation-name: levitating;
      -webkit-animation-duration: 1000ms;
      -webkit-transform-origin: 50% 50%;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
    }

    .trevor-message-bubble {
      height: 100%;
      max-width: 396px; // 460-64
      // line-height: 54px;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;

      -ms-flex-align: center;
      -webkit-align-items: center;
      -webkit-box-align: center;

      align-items: center;
      justify-content: center;
      @media (max-width: $mobileBreakPoint) {
        width: 220px !important; // 380-64
      }
      @media (max-width: $smallScreenBreakpoint) {
        width: 316px; // 380-64
      }
      // transition: opacity 5s;
      // -webkit-transition: opacity 5s;
      & > * {
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 18px;
        color: white;
        margin: 5px 10px;
        width: 100%;
        overflow: hidden;
        opacity: 0.333;
        &:hover {
          opacity: 1;
          text-decoration: none;
        }
      }
    }
  }
}

// <TasksPanel/>
#tasks-panel {
  #tasks-panel-tabs-toolbar .MuiSelect-select {
    // reusable component
    padding-left: 5px !important;
  }
  #add-new-task-panel .MuiSelect-root {
    // reusable component
    max-width: 60px;
    .MuiSelect-select {
      font-size: 1.4em;
      font-weight: 200;
      line-height: 48px;
    }
  }
  // class Inbox
  #inbox {
    .inbox-helper {
      margin-top: 36px;
      p {
        margin: 10px;
        line-height: 28px;
        vertical-align: middle;
        svg {
          display: inline-block;
          vertical-align: middle;
        }
        a {
          display: inline-block;
          line-height: 28px;
          vertical-align: middle;
        }
      }
      .inbox-helper-link {
        color: rgba(0, 0, 0, 0.87);
        &:hover {
          color: black;
          text-decoration: underline;
        }
      }
    }
  }
}

#add-new-task-panel .list-dropdown-placeholder {
  max-width: 48px;
  margin: 0 16px 0 8px;
}

// MOBILE AddNewTaskPanel - if is within Dialog
.MuiDialog-root #add-new-task-panel {
  min-height: 56px;
  height: auto;
  padding: 15px;
  .add-new-task-panel-container {
    flex-direction: column;
    gap: 5px;
    & > * {
      width: 100% !important;
    }
    .add-new-task-panel-lists {
      width: 100%;
      margin-bottom: 5px;
      overflow: visible;
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 9px;
      .MuiChip-root {
        margin: 10px !important;
        float: none !important;
      }
    }
    .list-dropdown-placeholder {
      max-width: 100%;
      width: 100%;
    }
  }
}

.user-select-none {
  user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none;
}

.truncate {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// class TASK
.task-card {
  -webkit-touch-callout: none;
  // -webkit-user-select: none; /* Disable selection/copy in UIWebView */
  &.slideOutRight {
    width: 100%;
    max-width: 460px;
    z-index: 1202;
    @media (max-width: $mobileBreakPoint) {
      max-width: inherit;
    }
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
}

.task-card .ql-editor {
  padding-left: 12px;
}

.title-with-markdown {
  display: inline-block;
  p {
    margin: 0;
  }
}

// class AddNewTaskPanel - Required for 1st step of walkthrough
#add-new-task-panel {
  &.walkthrough {
    position: absolute;
    width: 460px;
    @media (max-width: $mobileBreakPoint) {
      bottom: 0px;
    }
    bottom: 250px;
    margin: 0 auto;
    z-index: 1103 !important; // 1 above overlay
  }
}

.progress-controls {
  margin: 0;
  padding: 0;
  .progress-controls-scroller {
    height: 64px;
    width: auto;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    @media (max-width: $mobileXSBreakPoint) {
      height: 74px; // accomodates scrollbar
    }
    button {
      vertical-align: middle;
    }
  }
}

.recharts-legend-wrapper {
  overflow-y: auto;
}

// Used for Drag n drop behavour of react-beautiful-dnd
.task-in-move {
  transition-duration: 0.001s !important; // Prevents react-beautiful-dnd drop animation from visually returning task to inbox when scheduling
  // Do not use transform as it's used by dnd
  border-radius: 8px;
  overflow: hidden;
  @media (max-width: $mobileBreakPoint) {
    // width: 75%;
    display: none; // fixes bug when placeholder stays on the screen during dragging and after dropping
  }
  border-radius: 8px;
  z-index: 100 !important;
  opacity: 0.75;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4) !important;
  // Hide All extra components
  height: auto !important;
  .task-card {
    & > div:not(:first-child) {
      display: none;
    }
  }
}

// Sets grab cursor only for TaskTitleWithControls
.task-card {
  & > div {
    // DraggableWithTaskProps
    cursor: initial !important;
  }
  & > div:first-child {
    // TaskTitleWithControls
    cursor: grab !important;
  }
}

// .ui-sortable {
//   padding-bottom: 25px; // Helps to not drop a task outside the list
// }

// .ui-sortable-helper {
//   .task-card {
//     box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
//     opacity: 0.95;
//     -ms-transform: rotate(-3deg);
//     -webkit-transform: rotate(-3deg);
//     transform: rotate(-3deg);
//     max-height: 48px;
//     overflow: hidden;
//   }
// }
// .ui-sortable-placeholder {
//   border: none !important;
//   background-color: rgba(80, 150, 190, 0.1);
//   max-height: 48px;
//   -ms-transform: rotate(0deg);
//   -webkit-transform: rotate(0deg);
//   transform: rotate(0deg);
// }

// .task-smart-suggestions-bar > div:first-child {
//   -webkit-animation-duration: 1s;
//   animation-duration: 1s;
//   -webkit-animation-fill-mode: both;
//   animation-fill-mode: both;
//   -webkit-animation-name: pulse;
//   animation-name: pulse;
// }

.react-flow {
  .task {
    border-radius: 5px !important;
    // .ql-editor {
    // 	max-height: 400px;
    // }
  }
  .graph-note {
    border-radius: 5px !important;
    .graph-note-title {
      font-weight: bold;
      padding: 0 10px;
      width: 90% !important;
      hr {
        border-color: #efefef !important;
      }

      &.compact-graph-note-title {
        font-size: 1.6rem !important;
        font-weight: 400;
        hr {
          border: none !important;
        }
      }
    }
    .note-container {
      padding: 10px;

      .show-more-less {
        bottom: 0;
        right: 0;
      }
    }
  }
  .react-flow__handle {
    height: 12px;
    width: 12px;
    background: #dadada;
    opacity: 0.5;
    z-index: 2;
  }
  .react-flow__handle-right {
    right: -6px;
  }
  .react-flow__handle-left {
    left: -6px;
  }
}
.graph-compact-task-title {
  font-size: 1.6rem !important;
  font-weight: 500;
  .task-title {
    line-height: 1.75rem !important;
  }
}
.graph-control-bar {
  position: absolute;
  padding: 0 10px;
  margin: 1px 15px;
  border-radius: 0 0 5px 5px !important;
  z-index: 10;
  display: flex;
  align-items: center;
  .graph-selector-wrapper {
    margin: -5px 0 -7px 0;
  }
}

// .drag-handle {
// 	position: absolute;
// 	top:50%;
// 	// looks better on top:50% but there's some weird element under checkbox that's blocking it, setting z-index to overcome this
// 	z-index: 1000;
// 	left: 14px;
// 	color: #ccc;
// }
.task-placecholder {
  border: 2px dotted grey;
  height: 90px;
}

#login-bg {
  .loader {
    margin: 3em auto;
  }
  .temp-unavailable {
    color: #fbe9e7;
    padding: 10px 0;
  }

  // #firebaseui-auth-container .firebaseui-list-item:first-child {
  //   display: none;
  // }
}

.loader {
  // center
  margin: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.loader {
  // border: 5px solid #f3f3f3;
  border: 5px solid white;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  // border-top: 5px solid #555;
  border-top: 5px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-moz-keyframes logospin {
  100% {
    -moz-transform: rotate(120deg);
  }
}
@-webkit-keyframes logospin {
  100% {
    -webkit-transform: rotate(120deg);
  }
}
@keyframes logospin {
  100% {
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg);
  }
}

.animate-logospin {
  -webkit-animation: logospin 1s ease-in-out;
  -moz-animation: logospin 1s ease-in-out;
  -o-animation: logospin 1s ease-in-out;
  animation: logospin 1s ease-in-out;
}

.init-message {
  position: fixed;
  margin: auto;
  bottom: 35px;
  width: 100%;
  text-align: center;
  color: #ddd;
  font-size: 1em;
  font-weight: 300;
}

.unread-indicator {
  height: 10px;
  width: 10px;
  background-color: $orange;
  border-radius: 50%;
  display: inline;
}
.unread-indicator.badge {
  position: absolute;
  top: 2px;
  left: 2px;
}

/* SmallChat */
#Smallchat {
  @media (max-width: $mobileBreakPoint) {
    display: none;
    // bottom: -19px !important;
  }
}

/* Bootstrap Tour */
.popover {
  padding: 0 !important;
  border: none;
  z-index: 1500 !important; // task panel is 1300
  border-radius: 30px;
  & > .arrow:after {
    border-top-color: $primary-color !important;
    border-bottom-color: $primary-color !important;
  }
  .arrow {
    border: none;
  }
  h3,
  .popover-title {
    background-color: $primary-color;
    color: white;
    font-weight: 500;
    border: none;
    padding-left: 40px;
    padding-right: 30px;
    border-radius: 30px;
  }
  .popover-content {
    display: none;
  }
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
  // @media (max-width: $mobileBreakPoint) {
  // 	display: none !important;
  // }
  .popover-navigation {
    .btn[data-role='next'] {
      background-color: $primary-color;
      color: white;
      border: none;
    }
  }
  .popover-logo {
    position: absolute;
    width: 26px;
    height: 26px;
    left: 5px;
    top: 5px;
    -webkit-animation: logospin 2s ease-in-out infinite;
    -moz-animation: logospin 2s ease-in-out infinite;
    animation: logospin 2s ease-in-out infinite;
  }
  .skip {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    text-decoration: none;
    padding: 6px 12px;
    font-size: 16px;
  }
  .popover.bottom {
    margin-top: 20px !important;
  }
}

.master-schedule-tooltip.MuiTooltip-popper {
  z-index: 10;
}

.hidden-mobile {
  @media (max-width: $mobileBreakPoint) {
    display: none !important;
  }
}

.hidden-mobile-xs {
  @media (max-width: $mobileXSBreakPoint) {
    display: none !important;
  }
}

.hidden-desktop {
  @media (min-width: $mobileBreakPoint) {
    display: none !important;
  }
}

.hidden {
  display: none !important;
}

.drawer {
  overflow-y: auto;
  overflow-y: hidden;
  .drawer-timer {
    width: 100%;
    text-align: center;
    padding-top: 20px;
    .timer-time {
      color: $primary-color;
      font-size: 3em;
      font-weight: 100;
      text-decoration: none;
    }
    .timer-subtext {
      color: rgb(165, 165, 165);
      margin: 5px auto 20px auto;
    }
  }
  .drawer-task-title {
    font-size: 1.3em;
    font-weight: 300;
    margin: 0 20px;
    display: inline-block;
  }
  .drawer-task-list {
    float: right;
  }
}

.date-time-picker-button {
  // color: $primary-color;
}

.cc-window {
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.5);
}

.walkthrough-overlay {
  // Styles in SX
  h1 {
    padding-top: 118px;
    display: none;
  }
  p {
    font-size: 1.2em;
    display: none;
  }
  .walkthrough-start-mobile {
    display: none;
  }
  a {
    color: white;
    &.walkthrough-skip {
      position: absolute;
      bottom: 5em;
    }
  }
}

.snackbar {
  @media (max-width: $mobileBreakPoint) {
    margin-bottom: 56px;
    overflow: hidden;
  }
}

.shared-dialog-content {
  max-width: 420px !important;
}

// CALENDAR STYLING
@import 'calendar.scss';

.login-entry-logged-in {
  height: 100%;
  display: flex;
  color: white;
  font-size: 1.2em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.day-label {
  padding: 1em 0.7em;
  opacity: 0.6;
  font-size: 1.05em;
  font-weight: 300;
}

.initial-dialog {
  @media (max-width: $mobileXSBreakPoint) {
    p,
    li,
    input {
      font-size: 0.9em;
    }
    h3 {
      font-size: 1em !important;
    }
  }
}

.cc-window {
  // doesn't allow edit via window.cookieconsent.initialise()
  margin: 6px;
  border-radius: 24px;
  .cc-btn {
    border-radius: 8px;
  }
}

// Disable Recaptcha banner
.grecaptcha-badge {
  display: none;
}

.main-menu {
  a {
    color: inherit;
    text-decoration: none;
  }
  .premium-menu-link {
    a {
      // Fill menu item, including the icon
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      padding-left: 50px;
    }
  }
}
// #Smallchat {
//   display: none !important;
// }

// Animated Gradient Background for personalisation
// @keyframes gradient {
//   0% {
//     background-position: 0% 50%;
//   }
//   50% {
//     background-position: 100% 50%;
//   }
//   100% {
//     background-position: 0% 50%;
//   }
// }

// #app_root {
//   // background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab) !important;
//   background-size: 400% 400% !important;
//   animation: gradient 30s ease infinite !important;
// }

// Blurry backdrop in focus mode
@supports ((backdrop-filter: blur(5px)) or (-webkit-backdrop-filter: blur(5px))) {
  .drawer.isExtended .MuiModal-backdrop {
    backdrop-filter: blur(5px);
  }
}

.task-parent-path {
  // color: rgba(0, 0, 0, 0.6);
  opacity: 0.6;
}
.task-parent-path.title-with-markdown {
  margin-right: 0.2rem;
}

@media (max-width: $mobileBreakPoint) {
  #account-settings > .MuiStack-root > .MuiGrid-root,
  #account-settings > .MuiStack-root > .MuiGrid-root > .MuiGrid-root {
    padding-left: 0;
    padding-right: 0;
  }

  .wider-list-on-mobile {
    > li {
      padding-left: 0;
      padding-right: 0;

      > .MuiListItemSecondaryAction-root {
        right: 0;
      }
    }
  }
}
